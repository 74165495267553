<template>
    <section>
      <div class="rounded-md shadow-md">
        <DataTable
          :value="medicamentos"
          responsiveLayout="scroll"
          headerClass="bg-white"
          class="lg:text-xs pb-1 px-1"
          dataKey="id"
          @sort="sort"
          :rowClass="rowClass"
          editMode="cell"
          @cell-edit-complete="onCellEditComplete"
        >
          <template #empty>
            No existen recepciones registradas
          </template>
          <Column field="state" header="" style="max-width:2rem;" headerClass="lg:text-xs" class="my-1 py-1 shadow-sm">
            <template #body="{data}">
              <input :disabled="tryRecepcion.estado === 'EN STOCK' ? 'disabled' : false" v-model="data.state" type="checkbox">
            </template>
          </Column>
          <Column field="medicamento" header="Medicamento" headerClass="lg:text-xs" class="my-1 py-1 shadow-sm">
            <template #body="{data}">
              <div class="w-full h-full">
                <p class="lg:text-xs flex items-center">
                  {{ data.itemName}}
                </p>
                <p class="lg:text-xs flex items-center">
                  <span class="font-bold">Código: </span>{{ data.itemCode}}
                </p>
              </div>
            </template>
          </Column>
          <Column field="lote" header="Lote" headerClass="lg:text-xs" class="my-1 py-1 shadow-sm">
            <template #body="{data}">
              <div class="w-full h-full">
                <p class="lg:text-xs flex items-center">
                  <span class="font-bold mr-2">N°: </span>{{ data.lote}}
                </p>
                <p class="lg:text-xs flex items-center">
                  <span class="font-bold mr-2">Vcto: </span>{{ data.fecVen }}
                </p>
              </div>
            </template>
          </Column>
          <Column field="cantidadEnv" header="Cant Env." headerClass="lg:text-xs" class="my-1 py-1 shadow-sm" style="min-width:4rem; color: #4A4A4A;">
            <template #body="{data}">
              <div class="w-full h-full">
                <p v-if="data.adicional" class="lg:text-xs flex items-center">
                  0
                </p>
                <p v-else class="lg:text-xs flex items-center">
                  {{ data.cantidad}}
                </p>
              </div>
            </template>
          </Column>
          <Column field="cantidadRec" header="Cant Recep" headerClass="lg:text-xs" class="my-1 py-1 shadow-sm" style="max-width:5rem; color: #4A4A4A;">
            <template #body="{data}">
              <div class="w-full h-full">
                <div v-if="data.adicional">
                  <p>
                    {{ data.cantidad }}
                  </p>
                </div>
                <InputNumber v-else inputStyle="width:10%" inputId="integeronly" v-model="data.cantRec" />
              </div>
            </template>
          </Column>
          <Column header="Novedades"
            headerClass="lg:text-xs"
            style="font: 15px/18px; max-width: 15rem"
            class="shadow-sm"
          >
            <template class="lg:text-xs" #body="{data}">
              <div v-if="data.adicional" class="overflow-y-scroll max-h-20 max-w-xs">
                <div class="p-1 bg-gray-600 text-white m-1 flex rounded-sm min-w-fit">{{data.comentario}}</div>
              </div>
              <div v-else>
                <div v-if="data.novedades.length" class="overflow-y-scroll max-h-20">
                  <div class="flex flex-wrap">
                    <div v-for="(novedadFor, i) in data.novedades" :key="i" class="lg:text-xs flex items-center">
                      <div class="p-1 bg-gray-600 text-white m-1 flex rounded-sm">{{novedadesSelect.filter(a => a.id === novedadFor.idNovedad)[0].nombre}}</div>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <Button
                    :disabled="data.adicional || tryRecepcion.estado === 'EN STOCK' ? 'disabled' : false"
                    @click="openModal(data)"
                    label="Agregar novedad"
                    class="border-gray-400 font-bold w-full py-1 lg:text-xs"
                    :class="data.cantidad !== data.cantRec ? 'bg-red-200 text-red-600' : 'bg-gray-200 text-gray-600'"
                  />
                  <span v-if="data.cantidad !== data.cantRec" class="text-red-600">Es obligatoria la novedad ya que las cantidades no coinciden</span>
                </div>
              </div>
            </template>
          </Column>
          <Column field="acciones" class="my-1 py-1 shadow-sm">
            <template #header>
              <div class="flex items-center" v-if="tryRecepcion.estado !== 'EN STOCK'">
                <label for="barCodeMd">
                  <svg style="width:24px;height:24px; cursor: pointer" viewBox="0 0 24 24">
                      <path fill="black" d="M4,6H6V18H4V6M7,6H8V18H7V6M9,6H12V18H9V6M13,6H14V18H13V6M16,6H18V18H16V6M19,6H20V18H19V6M2,4V8H0V4A2,2 0 0,1 2,2H6V4H2M22,2A2,2 0 0,1 24,4V8H22V4H18V2H22M2,16V20H6V22H2A2,2 0 0,1 0,20V16H2M22,20V16H24V20A2,2 0 0,1 22,22H18V20H22Z" />
                  </svg>
                </label>
                <input
                  id="barCodeMd"
                  @keyup.enter="findMdByCodeBar"
                  v-model="barCodeMd"
                  :class="barCodeMd ? 'bg-green-600 border-green-600 text-green-600': ''"
                  class="mx-2 bg:transparent border-0 w-2 h-3 rounded-full text-green-600 focus:border-green-600 focus:bg-green-600 focus:shadow-md "
                  style="width: 10px;"
                  type="text"
                  autofocus
                >
                <p class="ml-2">
                  {{barCodeMd}}
                </p>
                <button v-if="barCodeMd" @click="barCodeMd = ''" class="rounded-full mx-2 w-4 flex items-center justify-center h-4 bg-red-600">
                  <i class="pi pi-times text-white" style="font-size: 0.5rem;"></i>
                </button>
              </div>
            </template>
            <template #body="{data}">
              <div class="flex">
                <div class="w-full h-full">
                  <Button
                    v-if="data.novedades.length"
                    @click="openModal(data)"
                    icon="pi pi-eye"
                    class="font-bold w-full py-1 lg:text-xs" />
                  <Button
                    v-else
                    @click="openModal(data)"
                    icon="pi pi-eye"
                    disabled="disabled"
                    class="font-bold w-full py-1 lg:text-xs bg-blue-300 border-0" />
                </div>
                <div>
                  <Button
                    v-if="data.adicional"
                    @click="borrarMedicamento(data)"
                    icon="pi pi-trash"
                    class="font-bold w-full px-4 lg:text-xs bg-red-600 border-0" />
                </div>
              </div>
            </template>
          </Column>
        </DataTable>
        <div v-if="medicamentoFueraDeOficio">
          <div class="lg:flex gap-4 my-2 py-4 px-2">
            <p class="font-bold text-lg lg:hidden mb-4">Agregar medicamento +</p>
            <span style="width: 8%;" class="lg:text-xs">
              <label  class="lg:text-xs mb-2">Codigo</label>
              <br />
              <InputText type="text" class="w-full mt-1" inputStyle="width:5%" v-model="medicamentoAdicional.itemCode" />
            </span>
            <span style="width: 52%;">
              <label class="lg:text-xs">Nombre medicamento</label>
              <InputText type="text" v-model="medicamentoAdicional.itemName" class="w-full" inputStyle="width:55%" />
            </span>
            <span style="width: 5%;">
              <label class="lg:text-xs">Lote</label>
              <InputText class="w-full" v-model="medicamentoAdicional.lote" />
            </span>
            <span class="grid w-full lg:w-44">
              <label class="lg:text-xs">Fecha ven.</label>
              <input class="p-button-secondary border py-2 px-2 rounded-md flex-items-center" v-model="medicamentoAdicional.fecVen" type="date" />
            </span>
            <span class="grid">
              <label class="lg:text-xs">Cant rec.</label>
              <InputNumber :min="0" :max="999999999999999" inputClass="w-full lg:w-12" v-model="medicamentoAdicional.cantidad" />
            </span>
            <span style="width: 22%;">
              <label class="lg:text-xs">Comentarios</label>
              <InputText class="w-full" v-model="medicamentoAdicional.comentario" />
            </span>
            <div class="flex justify-center gap-4 w-full lg:w-44 mt-4">
              <Button @click="agregarMedicamento" class="bg-white hover:bg-white border-0 text-gray-600" icon="pi pi-save"></Button>
              <Button @click="medicamentoFueraDeOficio = false" class="bg-white hover:bg-white border-0 text-gray-600" icon="pi pi-times"></Button>
            </div>
          </div>
        </div>
        <div class="py-4 px-2">
          <Button
            @click="medicamentoFueraDeOficio = !medicamentoFueraDeOficio"
            class="bg-transparent border-0 text-gray-600 lg:text-xs lg:inline"
            :disabled="tryRecepcion.estado === 'EN STOCK' ? 'disabled' : false"
            label="+ Agregar medicamento no registrado">
          </Button>
        </div>
      </div>
    </section>
    <Dialog v-model:visible="displayModal" :closable="false" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '50vw'}" :modal="true">
      <template #header>
        <div class="flex mr-2 items-center">
          <i class="pi pi-plus mr-2 font-bold lg:text-xs"></i>
          <h3 class="font-bold">Agregar novedad</h3>
        </div>
      </template>
      <DataTable
        :value="arrayNovedadesTemporales"
        responsiveLayout="scroll"
        headerClass="bg-white"
        class="lg:text-xs"
        v-model:editingRows="editingRows"
        editMode="row"
        dataKey="Id"
        @row-edit-save="onRowEditSave"
      >
        <template #empty>
          <div class="lg:flex items-start gap-4 my-2">
            <Dropdown
              v-model="novedad.idNovedad"
              :options="novedadesOptionModal"
              placeholder="Seleccionar novedad"
              inputClass="text-xs pb-1"
              panelClass="text-xs pb-1"
              class="text-xs rounded-md border-gray-300 pb-1 w-full lg:w-4/12"
              optionValue="id"
              optionLabel="nombre"
            />
            <div class="w-full lg:w-1/12">
              <InputNumber inputClass="w-full lg:w-1/12 my-2 lg:my-0" v-model="novedad.cantidad" />
            </div>
            <Dropdown
              v-model="novedad.comentario"
              v-if="novedad.idNovedad === 6 || novedad.idNovedad === 5 || novedad.idNovedad === 4"
              :options="novedad.idNovedad === 6 ? novedadesDetails.critico : (novedad.idNovedad === 5 ? novedadesDetails.mayor : novedadesDetails.menor)"
              placeholder="Seleccionar novedad"
              inputClass="text-xs pb-1"
              panelClass="text-xs pb-1"
              class="text-xs rounded-md border-gray-300 pb-1 w-full lg:w-5/12"
              optionValue="nombre"
              optionLabel="nombre"
            />
            <Textarea v-else v-model="novedad.comentario" rows="3" class="w-full lg:w-5/12" />
            <div style="min-width:8rem" class="flex justify-center gap-4 w-full lg:w-1/12">
              <Button @click="guardarNovedad" class="bg-white hover:bg-white border-0 text-gray-600" icon="pi pi-save"></Button>
              <Button @click="agregarNovedad = false" class="bg-white hover:bg-white border-0 text-gray-600" icon="pi pi-times"></Button>
            </div>
          </div>
        </template>
        <Column field="idNovedad" header="Tipo" style="width:30%">
            <template #editor="{ data }">
              <Dropdown v-model="data.idNovedad" :options="novedadesOptionModal" optionLabel="nombre" optionValue="id" placeholder="Seleccionar novedad">
                <template #option="slotProps">
                  {{ slotProps }}
                        <span :class="'product-badge status-' + slotProps.option.value.toLowerCase()">{{slotProps.option.label}}</span>
                    </template>
                </Dropdown>
            </template>
            <template #body="slotProps">
                {{novedadesSelect.filter(a => a.id === slotProps.data.idNovedad)[0].nombre}}
            </template>
        </Column>
        <Column field="cantidad" header="Cantidad" style="width:10%">
            <template #editor="{ data }">
                <InputText v-model="data.cantidad" />
            </template>
        </Column>
        <Column field="comentario" header="Observaciones" style="width:40%">
            <template #editor="{ data }">
                <InputText v-model="data.comentario" />
            </template>
        </Column>
        <Column :rowEditor="true" style="width:15%; min-width:8rem" bodyStyle="text-align:center">
          <template v-if="arrayNovedadesTemporales.length" #header>
            <Button @click="agregarNovedad = true" style="inline-size: max-content;" class="bg-transparent border-0 text-gray-600 text-xs" label="+ Agregar novedad"></Button>
          </template>
        </Column>
        <Column field="Cantidad" style="width:15%">
            <template #body="{data}">
              <div class="w-full h-full">
                <Button @click="borrarNovedad(data)" class="bg-transparent border-0 text-red-600 text-xs" icon="pi pi-trash"></Button>
              </div>
            </template>
        </Column>
      </DataTable>
      <div v-if="agregarNovedad" class="lg:flex items-start gap-4 my-2">
        <Dropdown
          v-model="novedad.idNovedad"
          :options="novedadesOptionModal"
          placeholder="Seleccionar novedad"
          inputClass="text-xs pb-1"
          panelClass="text-xs pb-1"
          class="text-xs rounded-md border-gray-300 pb-1 w-full lg:w-4/12"
          optionValue="id"
          optionLabel="nombre"
        />
        <div class="w-full lg:w-1/12">
          <InputNumber inputClass="w-full lg:w-1/12 my-2 lg:my-0" v-model="novedad.cantidad" />
        </div>
        <Dropdown
            v-model="novedad.comentario"
            v-if="novedad.idNovedad === 6 || novedad.idNovedad === 5 || novedad.idNovedad === 4"
            :options="novedad.idNovedad === 6 ? novedadesDetails.critico : (novedad.idNovedad === 5 ? novedadesDetails.mayor : novedadesDetails.menor)"
            placeholder="Seleccionar novedad"
            inputClass="text-xs pb-1"
            panelClass="text-xs pb-1"
            class="text-xs rounded-md border-gray-300 pb-1 w-full lg:w-5/12"
            optionValue="nombre"
            optionLabel="nombre"
          />
        <Textarea v-else v-model="novedad.comentario" rows="3" class="w-full lg:w-5/12" />
        <div style="min-width:8rem" class="flex justify-center gap-4 w-full lg:w-1/12">
          <Button @click="guardarNovedad" class="bg-white hover:bg-white border-0 text-gray-600" icon="pi pi-save"></Button>
          <Button @click="agregarNovedad = false" class="bg-white hover:bg-white border-0 text-gray-600" icon="pi pi-times"></Button>
        </div>
      </div>
      <template #footer>
        <div class="w-full flex justify-center gap-4">
          <Button label="CANCELAR"  @click="closeModal" class="bg-white hover:bg-gray-600 focus:bg-gray-600 text-gray-800 hover:text-gray-800 border-0"/>
          <Button label="CONFIRMAR"  @click="guardarNovedades" class="bg-gray-600 hover:bg-gray-600 focus:bg-gray-600 text-white hover:text-white border-0" autofocus />
        </div>
      </template>
    </Dialog>
  </template>
  <script>
  import { ref, onMounted, computed, watch } from 'vue'
    import recepcionStore from '../../../../../store/recepcion.store'
    import NovedadesTipoService from '../../../../../services/novedades-tipo.service'
    import RecepcionService from '../../../../../services/recepcion.service.js'
    import Swal from 'sweetalert2'
    export default {
    name: 'ComponenteTableRecepcion',
    setup () {
      // Services
      const _NovedadesTipoService = ref(new NovedadesTipoService())
      const _RecepcionService = ref(new RecepcionService())
      // References
      const medicamentos = ref([])
      const agregarNovedad = ref(false)
      const displayModal = ref(false)
      const arrayNovedadesTemporales = ref([])
      const novedadesTemporales = ref([])
      const editingRows = ref([])
      const novedadesSelect = ref([])
      const novedadesOptionModal = ref([])
      const novedadesFinales = ref([])
      const medicamentoFueraDeOficio = ref(false)
      const codigoBarrasEscrito = ref(0)
      const inputFucusCantRec = ref()
      const novedadesDetails = ref({})
      const barCodeMd = ref('')
      // Computed
      const tryRecepcion = computed(() => recepcionStore.getters._recepcion)
      // Data
      const novedad = ref({
        idNovedad: 0,
        cantidad: 0,
        comentario: '',
        Id: 0
      })
      const medicamentoAdicional = ref({
        docEntry: tryRecepcion.value.docEntry,
        docNum: tryRecepcion.value.numOficio,
        itemCode: '',
        itemName: '',
        lote: 0,
        cantidad: 0,
        idNovedad: 4,
        comentario: '',
        fecVen: ''
      })
      // Methods
      const getTiposNovedades = () => {
        _NovedadesTipoService.value.get().then(({ data }) => {
          novedadesSelect.value = data
          for (const novedad of data) {
            if (novedad.nombre === 'Recepción cantidad menor' || novedad.nombre === 'Recepción cantidad mayor') {
            } else {
              novedadesOptionModal.value.push(novedad)
            }
          }
        })
      }
      const getNovedadesDetail = () => {
        _NovedadesTipoService.value.getDetails().then(({ data }) => {
          novedadesDetails.value = data
        })
      }
      const openModal = (info) => {
        info.novedades.map(a => {
          arrayNovedadesTemporales.value.push({
            cantidad: a.cantidad,
            idNovedad: a.idNovedad,
            comentario: a.comentario,
            Id: a.Id
          })
        })
        novedadesTemporales.value = info
        displayModal.value = true
      }
      const closeModal = () => {
        displayModal.value = false
        arrayNovedadesTemporales.value = []
        novedad.value = {
          idNovedad: 0,
          cantidad: 0,
          comentario: '',
          Id: 0
        }
        agregarNovedad.value = false
      }
      const rowClass = (data) => {
        return data.state === true ? 'bg-1' : 'bg-2'
      }
      const sort = (evt) => {
        console.log({ evt })
      }
      const onRowEditSave = (event) => {
          const { newData, index } = event
          arrayNovedadesTemporales.value[index] = newData
      }
      const isPositiveInteger = (val) => {
          let str = String(val)
          str = str.trim()
          if (!str) {
              return false
          }
          str = str.replace(/^0+/, '') || '0'
          var n = Math.floor(Number(str))
          return n !== Infinity && String(n) === str && n >= 0
      }
      const onCellEditComplete = (event) => {
        const { field } = event
        switch (field) {
            case 'cantidadRec':
        }
      }
      const guardarNovedad = () => {
        if (!novedad.value.cantidad || !novedad.value.idNovedad) {
          Swal.fire({
            title: 'Advertencia',
            text: 'Por favor llene todos los campos de la novedad',
            icon: 'warning',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Ok'
          })
        } else if (!novedad.value.comentario) {
          Swal.fire({
            title: 'Advertencia',
            text: 'Por favor llene todos los campos de la novedad',
            icon: 'warning',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Ok'
          })
        } else {
          arrayNovedadesTemporales.value.push({
            cantidad: novedad.value.cantidad,
            idNovedad: novedad.value.idNovedad,
            comentario: novedad.value.comentario,
            Id: arrayNovedadesTemporales.value.length
          })
          novedad.value = {
            idNovedad: '',
            cantidad: 0,
            comentario: '',
            Id: 0
          }
          agregarNovedad.value = false
        }
      }
      const guardarNovedades = () => {
        if (novedadesFinales.value.length) {
          const arraynovFinFiltered = novedadesFinales.value.filter(a => a.itemCode === novedadesTemporales.value.itemCode && a.lote === novedadesTemporales.value.lote)
          for (const k of arraynovFinFiltered) {
            const index = novedadesFinales.value.findIndex(a => a.itemCode === k.itemCode && a.lote === k.lote)
            novedadesFinales.value.splice(index, 1)
          }
        }
        for (const i of medicamentos.value) {
          if (i.id === novedadesTemporales.value.id) {
            i.novedades = []
            for (const j of arrayNovedadesTemporales.value) {
              i.novedades.push({
                docNum: tryRecepcion.value.numOficio,
                docEntry: tryRecepcion.value.docEntry,
                itemCode: i.itemCode,
                lote: i.lote,
                cantidad: j.cantidad,
                idNovedad: j.idNovedad,
                comentario: j.comentario,
                Id: j.Id
              })
              novedadesFinales.value.push({
                docNum: tryRecepcion.value.numOficio,
                docEntry: tryRecepcion.value.docEntry,
                itemCode: i.itemCode,
                lote: i.lote,
                cantidad: j.cantidad,
                idNovedad: j.idNovedad,
                comentario: j.comentario
              })
            }
          }
        }
        arrayNovedadesTemporales.value = []
        displayModal.value = false
        novedad.value = {
          idNovedad: 0,
          cantidad: 0,
          comentario: '',
          Id: 0
        }
        agregarNovedad.value = false
      }
      const getDetailByBox = () => {
        _RecepcionService.value.detalleCaja(tryRecepcion.value.id).then(({ data }) => {
          for (const i of data) {
            if (!i.estadoCaja) {
              medicamentos.value.map(e => {
                if (e.itemCode === i.itemCode) {
                  e.cantRec = e.cantidad - i.cantidadMdCaja
                  e.novedades.push({
                    docNum: tryRecepcion.value.numOficio,
                    docEntry: tryRecepcion.value.docEntry,
                    itemCode: e.itemCode,
                    lote: e.lote,
                    cantidad: e.cantidad - i.cantidadMdCaja,
                    idNovedad: 1,
                    comentario: 'Sin caja recepcionada',
                    Id: e.novedades.length
                  })
                  novedadesFinales.value.push({
                    docNum: tryRecepcion.value.numOficio,
                    docEntry: tryRecepcion.value.docEntry,
                    itemCode: e.itemCode,
                    lote: e.lote,
                    cantidad: e.cantidad - i.cantidadMdCaja,
                    idNovedad: 1,
                    comentario: 'Sin caja recepcionada'
                  })
                }
              })
            } else {
              if (i.temp_entrada < 2 || i.temp_entrada > 8) {
                if (i.cadena_frio) {
                  medicamentos.value.map(e => {
                    if (e.itemCode === i.itemCode) {
                      e.novedades.push({
                        docNum: tryRecepcion.value.numOficio,
                        docEntry: tryRecepcion.value.docEntry,
                        itemCode: e.itemCode,
                        lote: e.lote,
                        cantidad: e.cantidad,
                        idNovedad: 10,
                        comentario: `Temperatura fuera de rango, la tamperatura de la caja en la cual venia el medicamento es de ${data.filter(o => o.itemCode === e.itemCode && o.lote === e.lote)[0].temp_entrada}`,
                        Id: e.novedades.length
                      })
                      novedadesFinales.value.push({
                        docNum: tryRecepcion.value.numOficio,
                        docEntry: tryRecepcion.value.docEntry,
                        itemCode: e.itemCode,
                        lote: e.lote,
                        cantidad: e.cantidad,
                        idNovedad: 10,
                        comentario: `Temperatura fuera de rango, la tamperatura de la caja en la cual venia el medicamento es de ${data.filter(o => o.itemCode === e.itemCode && o.lote === e.lote)[0].temp_entrada}°`
                      })
                    }
                  })
                }
              }
            }
          }
          guardarNovedades()
        })
      }
      const borrarNovedad = (data) => {
        const index = arrayNovedadesTemporales.value.findIndex(a => a.Id === data.Id)
        arrayNovedadesTemporales.value.splice(index, 1)
      }
      const agregarMedicamento = () => {
        if (Object.values(medicamentoAdicional.value).some(a => !a)) {
          Swal.fire(
            'Advertencia',
            'Debe llenar todos los datos del medicamento',
            'warning'
          )
        } else {
          if (medicamentos.value.some(a => a.itemCode === medicamentoAdicional.value.itemCode && a.lote === medicamentoAdicional.value.lote)) {
            Swal.fire({
              title: 'Advertencia',
              text: 'Ya existe un medicamento con el mismo código y lote, por favor buscalo y agrega una novedad',
              icon: 'warning',
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'Ok'
            })
          } else {
            novedadesFinales.value.push(medicamentoAdicional.value)
            medicamentos.value.push({
              ...medicamentoAdicional.value,
              novedades: [],
              adicional: true,
              state: true
            })
            medicamentoAdicional.value = {
              docEntry: tryRecepcion.value.docEntry,
              docNum: tryRecepcion.value.numOficio,
              itemCode: '',
              itemName: '',
              lote: 0,
              cantidad: 0,
              idNovedad: 4,
              comentario: '',
              fecVen: ''
            }
            medicamentoFueraDeOficio.value = false
          }
        }
      }
      const findMdByCodeBar = () => {
        medicamentos.value.map(a => {
          if (a.CodeBars === barCodeMd.value) {
            a.state = true
            barCodeMd.value = ''
          } else {
            Swal.fire(
              `<span style='line-height: initial;'>El medicamento ${barCodeMd.value} no se encuentra registrado en este oficio</span>`,
              '',
              'warning'
            ).then(() => (
              barCodeMd.value = ''
            ))
          }
        })
      }
      const borrarMedicamento = (data) => {
        const index = medicamentos.value.findIndex(a => a.itemCode === data.itemCode && a.lote === data.lote)
        medicamentos.value.splice(index, 1)
        const indexNov = novedadesFinales.value.findIndex(a => a.itemCode === data.itemCode && a.lote === data.lote)
        novedadesFinales.value.splice(indexNov, 1)
      }
      watch(novedadesFinales.value, (n, v) => {
        recepcionStore.commit('setNovedades', n)
      })
      watch(medicamentos.value, () => {
        for (const medicamento of medicamentos.value) {
          if (!medicamento.adicional) {
            if (medicamento.cantidad !== medicamento.cantRec) {
              if (medicamento.novedades.length) {
                const idNovedadMd = medicamento.cantRec > medicamento.cantidad ? 2 : 1
                if (medicamento.novedades.some(a => a.idNovedad === idNovedadMd)) {} else {
                  for (const novedad of medicamento.novedades) {
                    if (novedad.idNovedad === 1 || novedad.idNovedad === 2) {
                      const index = medicamento.novedades.indexOf(novedad.idNovedad)
                      medicamento.novedades.splice(index, 1)
                    }
                  }
                  medicamento.novedades.push({
                    Id: medicamento.novedades.length,
                    cantidad: medicamento.cantRec,
                    comentario: medicamento.cantRec > medicamento.cantidad ? 'Se recibe cantidad mayor' : 'Se recibe cantidad menor',
                    lote: medicamento.lote,
                    itemCode: medicamento.itemCode,
                    idNovedad: medicamento.cantRec > medicamento.cantidad ? 2 : 1,
                    docNum: tryRecepcion.value.numOficio,
                    docEntry: tryRecepcion.value.docEntry
                  })
                }
              } else {
                medicamento.novedades.push({
                  Id: medicamento.novedades.length,
                  cantidad: medicamento.cantRec,
                  comentario: medicamento.cantRec > medicamento.cantidad ? 'Se recibe cantidad mayor' : 'Se recibe cantidad menor',
                  lote: medicamento.lote,
                  itemCode: medicamento.itemCode,
                  idNovedad: medicamento.cantRec > medicamento.cantidad ? 2 : 1,
                  docNum: tryRecepcion.value.numOficio,
                  docEntry: tryRecepcion.value.docEntry
                })
              }
            } else {
              for (const novedad of medicamento.novedades) {
                if (novedad.idNovedad === 1 || novedad.idNovedad === 2) {
                  const index = medicamento.novedades.indexOf(novedad.idNovedad)
                  medicamento.novedades.splice(index, 1)
                }
              }
            }
          }
        }
        recepcionStore.commit('setRecepcionDetails', medicamentos.value)
      })

      onMounted(() => {
        getTiposNovedades()
        getNovedadesDetail()
        getDetailByBox()
        for (const medicamento of tryRecepcion.value.details) {
          medicamentos.value.push({
            ...medicamento,
            novedades: [],
            cantRec: medicamento.cantidad,
            state: false,
            focusCantRecp: false
          })
        }
      })
      return {
        medicamentos,
        displayModal,
        openModal,
        closeModal,
        novedad,
        novedadesSelect,
        onRowEditSave,
        editingRows,
        rowClass,
        sort,
        agregarNovedad,
        guardarNovedad,
        tryRecepcion,
        onCellEditComplete,
        isPositiveInteger,
        novedadesFinales,
        guardarNovedades,
        medicamentoAdicional,
        medicamentoFueraDeOficio,
        agregarMedicamento,
        borrarMedicamento,
        borrarNovedad,
        novedadesTemporales,
        arrayNovedadesTemporales,
        codigoBarrasEscrito,
        inputFucusCantRec,
        novedadesDetails,
        barCodeMd,
        findMdByCodeBar,
        novedadesOptionModal
      }
    }
  }
  </script>

  <style scoped>
    ::-webkit-scrollbar {
    width: 0.2rem;
  }
  .barcodeRead {
    width: 302px;
    height: 250px;
  }

  ::v-deep(.bg-1) {
    background: #EFF6FF !important;
    color: #1D4ED8 !important;
  }
  ::v-deep(.bg-2) {
    background-color: white !important;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: rgb(189, 189, 189);
    border-radius: 9999px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  .inline {
    inline-size: max-content;
  }

  /* ::v-deep(.p-datatable .p-datatable-tbody > tr.p-highlight) {
    background: red
  } */
  </style>
